<template>
  <div class="p-20">
    <v-row>
      <v-col cols="12"
             sm="12">
        <sb-input label="Card Required?" />
        <v-radio-group v-model="vehicle.productSetup.cardRequired"
                       row
                       @change="onCardRequiredSelect">
          <v-radio v-for="cardRequirement in cardRequirements"
                   :key="cardRequirement.key"
                   :label="cardRequirement.value"
                   :value="cardRequirement.key" />
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-if="showCardRequiredDetails">
      <v-col cols="12"
             sm="4">
        <sb-card-type-search v-model="vehicle.productSetup.primaryCardType"
                             rules="selected|required"
                             :card-type-default-value="vehicle.productSetup.primaryCardType"
                             :client-id="clientId"
                             ref-name="cardType"
                             @card-type="onCardType" />
      </v-col>
      <v-col cols="12"
             sm="4">
        <sb-card-type-search v-model="vehicle.productSetup.secondaryCardType"
                             label="Secondary card type"
                             :card-type-default-value="vehicle.productSetup.secondaryCardType"
                             :rules="{selected:true, isPrimaryCardTypeEqualSecondaryCardType: [this.vehicle.productSetup.primaryCardType]}"
                             :client-id="clientId"
                             ref-name="cardType"
                             @card-type="onSecondaryCardType" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"
             sm="6">
        <sb-input label="Complete the following" />
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th />
                <th class="text-left">
                  Yes / No
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <sb-input label="TruckFuelNet" />
                </td>
                <td>
                  <v-radio-group v-model="vehicle.productSetup.truckFuelNet"
                                 row
                                 @change="onTruckFuelSelect">
                    <v-radio v-for="truckFuelNet in truckFuelNets"
                             :key="truckFuelNet.key"
                             :value="truckFuelNet.key" />
                  </v-radio-group>
                  <span v-if="msg.truckFuelNet"
                        style="color:#d0021b">{{ msg.truckFuelNet }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <sb-input label="Oil Company Card" />
                </td>
                <td>
                  <v-radio-group v-model="vehicle.productSetup.oilCompanyCard"
                                 row
                                 @change="onOilCompanySelect">
                    <v-radio v-for="oilCompanyCard in oilCompanyCards"
                             :key="oilCompanyCard.key"
                             :value="oilCompanyCard.key" />
                  </v-radio-group>
                  <span v-if="msg.oilCompanyCard"
                        style="color:#d0021b">{{ msg.oilCompanyCard }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <sb-input label="BP FleetMove RFID" />
                </td>
                <td>
                  <v-radio-group v-model="vehicle.productSetup.bdfleetMoveRfid"
                                 row
                                 @change="onBpFleetSelect">
                    <v-radio v-for="bdfleetMoveRfid in bdfleetMoveRfids"
                             :key="bdfleetMoveRfid.key"
                             :value="bdfleetMoveRfid.key" />
                  </v-radio-group>
                  <span v-if="msg.bdfleetMoveRfid"
                        style="color:#d0021b">{{ msg.bdfleetMoveRfid }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <sb-input label="BlueFuel RFID - Namibia" />
                </td>
                <td>
                  <v-radio-group v-model="vehicle.productSetup.bdfleetMoveRfidsNamibia"
                                 row
                                 @change="onBdNamibiaSelect">
                    <v-radio v-for="bdfleetMoveRfidsNamibia in bdfleetMoveRfidsNa"
                             :key="bdfleetMoveRfidsNamibia.key"
                             :value="bdfleetMoveRfidsNamibia.key" />
                  </v-radio-group>
                  <span v-if="msg.bdfleetMoveRfidsNamibia"
                        style="color:#d0021b">{{ msg.bdfleetMoveRfidsNamibia }}</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="12"
             sm="12">
        <sb-input label="ORT ALLOWED?" />
        <v-radio-group v-model="vehicle.productSetup.ortAllowed"
                       row
                       disabled
                       @change="onOrtAllowedSelect">
          <v-radio v-for="ortAllowed in ortsAllowed"
                   :key="ortAllowed.key"
                   :label="ortAllowed.value"
                   :value="ortAllowed.key" />
        </v-radio-group>
      </v-col>
      <v-col cols="12"
             sm="12">
        <sb-input label="Register for ort?" />
        <v-radio-group v-model="vehicle.productSetup.registerForOrt"
                       row
                       :rules="isRequired"
                       @change="onRegisterAllowedSelect">
          <v-radio v-for="registerForOrt in registerForOrts"
                   :key="registerForOrt.key"
                   :label="registerForOrt.value"
                   :value="registerForOrt.key" />
        </v-radio-group>
        <span v-if="msg.registerForOrt"
              style="color:#d0021b">{{ msg.registerForOrt }}</span>
      </v-col>
    </v-row>
    <v-row v-if="showOrtAllowedDetails">
      <v-col cols="12"
             sm="3">
        <sb-text-field v-model="vehicle.productSetup.ortCountry"
                       label="Ort Country"
                       :maxlength="21"
                       disabled />
      </v-col>
      <v-col cols="12"
             sm="3">
        <sb-text-field v-model="vehicle.productSetup.ortRegion"
                       label="ORT Region"
                       :maxlength="21"
                       disabled />
      </v-col>
      <v-col cols="12"
             sm="3">
        <sb-text-field v-model="vehicle.productSetup.ortVehicleClass"
                       label="ORT Veh Class"
                       :maxlength="21"
                       disabled />
      </v-col>
      <v-col cols="12"
             sm="3">
        <sb-text-field v-model="vehicle.productSetup.ortAxleClass"
                       label="ORT AXLE CLASS"
                       :maxlength="21"
                       disabled />
      </v-col>
      <v-col cols="12"
             sm="3">
        <sb-input label="Dual Class" />
        <v-radio-group v-model="vehicle.productSetup.dualClass"
                       row
                       disabled
                       @change="onDualSelect">
          <v-radio v-for="dualClass in dualClasses"
                   :key="dualClass.key"
                   :label="dualClass.value"
                   :value="dualClass.key" />
        </v-radio-group>
        <span v-if="msg.dualClass"
              style="color:#d0021b">{{ msg.dualClass }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"
             sm="6">
        <sb-input label="Complete the following" />
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th />
                <th class="text-left">
                  Yes / No
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <sb-input label="CLIENT SUBSIDISER" />
                </td>
                <td>
                  <v-radio-group v-model="vehicle.productSetup.clientSubsidy"
                                 row
                                 :rules="isRequired"
                                 @change="onClientSubsidySelect">
                    <v-radio v-for="clientSubsidy in clientSubsidys"
                             :key="clientSubsidy.key"
                             :value="clientSubsidy.key" />
                  </v-radio-group>
                  <span v-if="msg.clientSubsidy"
                        style="color:#d0021b">{{ msg.clientSubsidy }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <sb-input label="Oil Co Subsidiser" />
                </td>
                <td>
                  <v-radio-group v-model="vehicle.productSetup.oilSubsidy"
                                 row
                                 @change="onClientSubsidySelect">
                    <v-radio v-for="clientSubsidy in clientSubsidys"
                             :key="clientSubsidy.key"
                             :value="clientSubsidy.key" />
                  </v-radio-group>
                  <span v-if="msg.oilSubsidy"
                        style="color:#d0021b">{{ msg.oilSubsidy }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <sb-input label="Wallet Required" />
                </td>
                <td>
                  <v-radio-group v-model="vehicle.productSetup.walletRequired"
                                 row>
                    <v-radio v-for="walletRequired in walletRequireds"
                             :key="walletRequired.key"
                             :value="walletRequired.key" />
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <td>
                  <sb-input label="Smash & Grab" />
                </td>
                <td>
                  <v-radio-group v-model="vehicle.productSetup.smashGrab"
                                 row>
                    <v-radio v-for="smashGrab in smashGrabs"
                             :key="smashGrab.key"
                             :value="smashGrab.key" />
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <td>
                  <sb-input label="Ignore Fixed Costs?" />
                </td>
                <td>
                  <v-radio-group v-model="vehicle.productSetup.ignoreFixedCost"
                                 row>
                    <v-radio v-for="ignoreFixedCost in ignoreFixedCosts"
                             :key="ignoreFixedCost.key"
                             :value="ignoreFixedCost.key" />
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <td>
                  <sb-input label="Ignore Odometer?" />
                </td>
                <td>
                  <v-radio-group v-model="vehicle.productSetup.ignoreOdometer"
                                 row>
                    <v-radio v-for="ignoreOdometer in ignoreOdometers"
                             :key="ignoreOdometer.key"
                             :value="ignoreOdometer.key" />
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <td>
                  <sb-input label="Driver to pay in house?" />
                </td>
                <td>
                  <v-radio-group v-model="vehicle.productSetup.driverToPayInHouse"
                                 row
                                 :disabled="driverToPay">
                    <v-radio v-for="driverToPayInHouse in driverToPayInHouses"
                             :key="driverToPayInHouse.key"
                             :value="driverToPayInHouse.key" />
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <td>
                  <sb-input label="Budget Option?" />
                </td>
                <td>
                  <v-radio-group v-model="vehicle.productSetup.budgetOption"
                                 row>
                    <v-radio v-for="budgetOption in budgetOptions"
                             :key="budgetOption.key"
                             :value="budgetOption.key" />
                  </v-radio-group>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"
             sm="4">
        <sb-admin-fee-search rules="selected|required"
                             :admin-fee-default-value="vehicle.productSetup.adminFeeGroup"
                             :client-id="clientId"
                             ref-name="adminFee"
                             @admin-fee="onAdminFee" />
      </v-col>
      <v-col cols="12"
             sm="4">
        <sb-authority-group-search rules="selected|"
                                   :authority-group-default-value="vehicle.productSetup.authorityGroup"
                                   :client-id="clientId"
                                   ref-name="authorityGroup"
                                   @authority-group="onAuthorityGroup" />
      </v-col>
      <v-col cols="12"
             sm="12">
        <sb-input label="Fraud Odo Ind" />
        <v-radio-group v-model="vehicle.productSetup.fraudOdo"
                       row
                       @change="onFraudSelect">
          <v-radio v-for="fraudOdo in fraudOdoInd"
                   :key="fraudOdo.key"
                   :label="fraudOdo.value"
                   :value="fraudOdo.key" />
        </v-radio-group>
        <span v-if="msg.fraudOdo"
              style="color:#d0021b">{{ msg.fraudOdo }}</span>
      </v-col>
      <v-col cols="12"
             sm="12">
        <sb-input label="Driver Allowance" />
        <v-radio-group v-model="vehicle.productSetup.driverAllowance"
                       row
                       @change="onDriverAllow">
          <v-radio v-for="driverAllow in driverAllowInd"
                   :key="driverAllow.key"
                   :label="driverAllow.value"
                   :value="driverAllow.key" />
        </v-radio-group>
        <span v-if="msg.driverAllow"
              style="color:#d0021b">{{ msg.driverAllow }}</span>
      </v-col>
      <v-col cols="12"
             sm="12">
        <sb-input label="Driver Billing" />
        <v-radio-group v-model="vehicle.productSetup.driveBilling"
                       row
                       @change="onFraudSelect">
          <v-radio v-for="driveBilling in driveBillingInd"
                   :key="driveBilling.key"
                   :label="driveBilling.value"
                   :value="driveBilling.key" />
        </v-radio-group>
        <span v-if="msg.driveBilling"
              style="color:#d0021b">{{ msg.driveBilling }}</span>
      </v-col>
      <v-col cols="12"
             sm="12">
        <sb-input label="Transaction Limit" />
        <v-radio-group v-model="vehicle.productSetup.transactionLimit"
                       row
                       @change="onTransactionLimitSelect">
          <v-radio v-for="transactionLimit in transactionLimits"
                   :key="transactionLimit.key"
                   :label="transactionLimit.value"
                   :value="transactionLimit.key" />
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row v-if="showTransactionLimitDetails">
      <v-col cols="12"
             sm="3">
        <sb-text-field v-model="vehicle.productSetup.monthLitreLimit"
                       label="Monthly Litre Limit Quantity" />
      </v-col>
      <v-col cols="12"
             sm="3">
        <sb-currency-field v-model="monthFuelAmout"
                           label="Monthly Fuel Limit Amount" />
      </v-col>
      <v-col cols="12"
             sm="3">
        <sb-currency-field v-model="monthRmAmount"
                           label="Monthly R&M Limit Amount" />
      </v-col>
      <v-col cols="12"
             sm="3">
        <sb-currency-field v-model="monthlyTollLimitAmount"
                           label="Monthly Toll Limit Amount" />
      </v-col>
      <v-col cols="12"
             sm="6">
        <sb-input label="Disallow transaction on Days (Y or blank)" />
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th />
                <th class="text-left">
                  Yes / No
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <sb-input label="Monday" />
                </td>
                <td>
                  <v-radio-group v-model="vehicle.productSetup.mon"
                                 row>
                    <sb-radio-button />
                    <sb-radio-button />
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <td>
                  <sb-input label="Tuesay" />
                </td>
                <td>
                  <v-radio-group v-model="vehicle.productSetup.tues"
                                 row>
                    <sb-radio-button />
                    <sb-radio-button />
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <td>
                  <sb-input label="Wednesday" />
                </td>
                <td>
                  <v-radio-group v-model="vehicle.productSetup.wed"
                                 row>
                    <sb-radio-button />
                    <sb-radio-button />
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <td>
                  <sb-input label="Thursday" />
                </td>
                <td>
                  <v-radio-group v-model="vehicle.productSetup.thurs"
                                 row>
                    <sb-radio-button />
                    <sb-radio-button />
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <td>
                  <sb-input label="Friday" />
                </td>
                <td>
                  <v-radio-group v-model="vehicle.productSetup.fri"
                                 row>
                    <sb-radio-button />
                    <sb-radio-button />
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <td>
                  <sb-input label="Saturday" />
                </td>
                <td>
                  <v-radio-group v-model="vehicle.productSetup.sat"
                                 row>
                    <sb-radio-button />
                    <sb-radio-button />
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <td>
                  <sb-input label="Sunday" />
                </td>
                <td>
                  <v-radio-group v-model="vehicle.productSetup.sun"
                                 row>
                    <sb-radio-button />
                    <sb-radio-button />
                  </v-radio-group>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="12"
             sm="3">
        <sb-text-field v-model="monthlyTotalLimitAmount"
                       :value="total"
                       disabled
                       label="Monthly Total Limit Amount" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12"
             sm="12">
        <sb-input label="Merchant Limit" />
        <v-radio-group v-model="vehicle.productSetup.merchantLimit"
                       row
                       @change="onMerchantLimitSelect">
          <sb-radio-button label="Yes" />
          <sb-radio-button label="No" />
        </v-radio-group>
      </v-col>
    </v-row>

    <template v-if="hasMerchantLimit">
      <v-row>
        <v-col cols="12" />

        <v-data-table :headers="BULK_SINGLE_VEHICLE_UPLOAD_HEADERS['merchantLimit']"
                      :items="vehicle.productSetup.sections.merchants"
                      hide-default-footer>
          <template v-if="!readOnly "
                    #item.merchantTypeCode="{item}">
            <sb-merchant-add-search :readonly="readOnly "
                                    :reference="{index: item.index}"
                                    :client-id="clientId"
                                    :current-value="item.merchantTypeCode"
                                    @item-update="updateJobCardItems" />
          </template>
          <template v-if="!readOnly || canUpdateAuthorisation"
                    #item.dailyTransCountLimitOVRD="{item}">
            <sb-numeric-field v-model.number="item.dailyTransCountLimitOVRD"
                              :min="0"
                              :max="100"
                              hide-details />
          </template>
          <template v-if="!readOnly || canUpdateAuthorisation"
                    #item.minHoursOVRD="{item}">
            <sb-numeric-field v-model.number="item.minHoursOVRD"
                              :min="0"
                              :max="100"
                              hide-details />
          </template>
          <template v-if="!readOnly || canUpdateAuthorisation"
                    #item.transactionValueLimitOverride="{item}">
            <sb-currency-field v-model.number="item.transactionValueLimitOverride"
                               hide-details />
          </template>
          <template #item.actions="{item}">
            <v-tooltip left>
              <template #activator="{on, attrs}">
                <v-icon :disabled="readOnly && !canUpdateAuthorisation"
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteItems(item)">
                  mdi-delete
                </v-icon>
              </template>
              <span>Delete Item</span>
            </v-tooltip>
          </template>
          <template #footer>
            <v-row>
              <v-col>
                <v-btn v-if="!readOnly || canUpdateAuthorisation"
                       color="secondary"
                       class="float-right"
                       depressed
                       :loading="createAuthorisationLoading"
                       :disabled="disabled"
                       @click="addItems()">
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  <span>Add Item</span>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-row>
    </template>

    <v-row>
      <v-col cols="12"
             sm="12">
        <sb-input label="Value-Add Products?" />
        <v-radio-group v-model="vehicle.productSetup.valueAdd"
                       row
                       @change="onValueAddedSelect">
          <sb-radio-button label="Yes" />
          <sb-radio-button label="No" />
        </v-radio-group>
      </v-col>
    </v-row>

    <template v-if="hasValueAddedProducts">
      <v-row>
        <v-col cols="12" />

        <v-data-table :headers="BULK_SINGLE_VEHICLE_UPLOAD_HEADERS['valueAddProducts']"
                      :items="vehicle.productSetup.sections.products"
                      hide-default-footer>
          <template v-if="!readOnly "
                    #item.productCode="{item}">
            <sb-product-value-add-search :readonly="readOnly "
                                         :reference="{index: item.index}"
                                         :client-id="clientId"
                                         :current-value="item.productCode"
                                         @item-update="updateJobCardItem" />
          </template>
          <template v-if="!readOnly "
                    #item.effectiveDate="{item}">
            <sb-date-picker-field v-model="item.effectiveDate"
                                  disabled />
          </template>

          <template v-if="!readOnly "
                    #item.serviceFee="{item}">
            <template v-if="item.systemInd === 'Y'">
              <sb-currency-field v-model.number="item.serviceFee" />
            </template>

            <template v-else>
              {{ item.serviceFee }}
            </template>
          </template>
          <template v-if="!readOnly "
                    #item.instalmentFee="{item}">
            <template v-if="item.systemInd === 'Y'">
              <sb-currency-field v-model.number="item.instalmentFee" />
            </template>

            <template v-else>
              {{ item.instalmentFee }}
            </template>
          </template>
          <template v-if="!readOnly "
                    #item.instalmentMonth="{item}">
            <template v-if="item.systemInd === 'Y'">
              <sb-numeric-field v-model.number="item.instalmentMonth"
                                :min="0"
                                :max="12" />
            </template>

            <template v-else>
              {{ item.instalmentMonth }}
            </template>
          </template>
          <template #item.actions="{item}">
            <v-tooltip left>
              <template #activator="{on, attrs}">
                <v-icon :disabled="readOnly && !canUpdateAuthorisation"
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </template>
              <span>Delete Item</span>
            </v-tooltip>
          </template>
          <template #footer>
            <v-row>
              <v-col>
                <v-btn v-if="!readOnly || canUpdateAuthorisation"
                       color="secondary"
                       class="float-right"
                       depressed
                       :loading="createAuthorisationLoading"
                       :disabled="disabled"
                       @click="addItem()">
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  <span>Add Item</span>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-row>
    </template>
  </div>
</template>

<script>
import {
  BULK_SINGLE_VEHICLE_UPLOAD_HEADERS,
  CUSTOMER_CARD_REQUIREMENTS,
  OIL_COMPANY_CARD,
  BD_FLEETMOVE_RFID,
  BD_FLEETMOVE_RFID_NAMIBIA,
  DRIVER_TO_PAY_IN_HOUSE,
  BUDGET_OPTION,
  TRUCK_FUEL_NET,
  CLIENT_SUBSIDY,
  OIL_SUBSIDY,
  SMASH_GRAB,
  DUAL_CLASS,
  FRAUD_ODO,
  DRIVER_ALLOW,
  DRIVE_BILLING,
  REGISTER_FOR_ORT,
  WALLET_REQUIRED,
  TRANSACTION_LIMIT,
  IGNORE_FIXED_COST,
  IGNORE_ODOMETER,
  SYSTEM_DEAL,
  ORT_ALLOWED,
} from '@/config/constants';
import { mapActions } from 'vuex';
import { extend } from 'vee-validate';
import SbCardTypeSearch from '@/components/client/SbCardTypeSearch';
import SbAdminFeeSearch from '@/components/client/SbAdminFeeSearch';
import SbAuthorityGroupSearch from '@/components/client/SbAuthorityGroupSearch';
import moment from 'moment';
import SbMerchantAddSearch from '@/components/client/SbMerchantAddSearch';
import SbProductValueAddSearch from '@/components/client/SbProductValueAddSearch';
import ProductSetupItemValueAdd from '../../models/vehicleCapture/ProductSetupItemValueAdd';
import MerchantSetupItemValueAdd from '../../models/vehicleCapture/MerchantSetupItemValueAdd';

extend('isPrimaryCardTypeEqualSecondaryCardType', {
  validate(value, [primaryCardType]) {
    return value.code !== primaryCardType;
  },
  message: 'SECONDARY CARD CANNOT BE THE SAME AS PRIMARY CARD  ',
});
extend('isRegisterForOrt', {
  validate(value, [ortRegister]) {
    return value === ortRegister;
  },

  message: 'Client must be registered for ORT ',
});

export default {
  components: {
    SbCardTypeSearch,
    SbAdminFeeSearch,
    SbAuthorityGroupSearch,
    SbProductValueAddSearch,
    SbMerchantAddSearch,
  },
  props: {
    vehicleEntity: {
      type: Object,
      default: () => {},
    },
    clientId: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      vehicle: this.vehicleEntity,
      BULK_SINGLE_VEHICLE_UPLOAD_HEADERS,
      cardRequirements: [CUSTOMER_CARD_REQUIREMENTS.YES, CUSTOMER_CARD_REQUIREMENTS.NO],
      oilCompanyCards: [OIL_COMPANY_CARD.YES, OIL_COMPANY_CARD.NO],
      truckFuelNets: [TRUCK_FUEL_NET.YES, TRUCK_FUEL_NET.NO],
      clientSubsidys: [CLIENT_SUBSIDY.YES, CLIENT_SUBSIDY.NO],
      oilSubsidys: [OIL_SUBSIDY.YES, OIL_SUBSIDY.NO],
      walletRequireds: [WALLET_REQUIRED.YES, WALLET_REQUIRED.NO],
      smashGrabs: [SMASH_GRAB.YES, SMASH_GRAB.NO],
      ignoreFixedCosts: [IGNORE_FIXED_COST.YES, IGNORE_FIXED_COST.NO],
      bdfleetMoveRfids: [BD_FLEETMOVE_RFID.YES, BD_FLEETMOVE_RFID.NO],
      bdfleetMoveRfidsNa: [BD_FLEETMOVE_RFID_NAMIBIA.YES, BD_FLEETMOVE_RFID_NAMIBIA.NO],
      registerForOrts: [REGISTER_FOR_ORT.YES, REGISTER_FOR_ORT.NO],
      driverToPayInHouses: [DRIVER_TO_PAY_IN_HOUSE.YES, DRIVER_TO_PAY_IN_HOUSE.NO],
      budgetOptions: [BUDGET_OPTION.YES, BUDGET_OPTION.NO],
      dualClasses: [DUAL_CLASS.YES, DUAL_CLASS.NO],
      fraudOdoInd: [FRAUD_ODO.YES, FRAUD_ODO.NO],
      driverAllowInd: [DRIVER_ALLOW.YES, DRIVER_ALLOW.NO],
      driveBillingInd: [DRIVE_BILLING.YES, DRIVE_BILLING.NO],
      transactionLimits: [TRANSACTION_LIMIT.YES, TRANSACTION_LIMIT.NO],
      systemDeals: [SYSTEM_DEAL.YES, SYSTEM_DEAL.NO],
      ortsAllowed: [ORT_ALLOWED.YES, ORT_ALLOWED.NO],
      ignoreOdometers: [IGNORE_ODOMETER.YES, IGNORE_ODOMETER.NO],
      hasSystem4Deal: false,
      hasTransactionLimit: false,
      showTransactionLimitDetails: false,
      showOrtAllowedDetails: true,
      hasMerchantLimit: false,
      hasValueAddedProducts: false,
      selected: [],
      secondaryProducts: [],
      ortCountries: [],
      ortRegion: [],
      ortVehClass: [],
      ortAxleClass: [],
      adminFeeGroup: [],
      authorityGroup: [],
      replacementMonthGroup: [],
      replacementKilometerGroup: [],
      exceptionGroup: [],
      maintenanceFundNumber: [],
      valueAddProducts: [],
      merchantLimit: [],
      productCodes: [],
      currentDate: moment().format('YYYY-MM-DD'),
      selectedMerchant: '',
      merchantTest: '',
      productSetup: { merchantDescription: '' },
      selectedItemIndex: -1,
      ortRegister: '',
      msg: [],
      subsidyClient: '',
      oilCompanySubsidy: '',
      oilCompanySub: '',
      tfn: '',
      driverToPay: false,
      serviceFee: true,
      instalmentFee: true,
      instalmentMonth: true,
      bpfleet: '',
      bpNamibia: '',
      fraudInd: '',
      oilCompany: '',
      newProduct: {
        productDesc: '',
        instalmentFee: 0,
        instalmentMonth: 0,
        serviceFee: 0,
        driverAllwnc: '',
        driverBillL: '',
      },
      newMerchant: {
        merchantDesc: '',
        dlyTransLimit: 0,
        minHrsBtwn: 0,
        transValLimit: 0,
      },
      fuelAmount: '',
      monthFuelAmout: '',
      monthRmAmount: '',
      rmAmount: '',
      tollAmount: '',
      monthlyTollLimitAmount: '',
      monthlyTotalLimitAmount: '',
    };
  },
  computed: {
    total() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.fuelAmount = this.monthFuelAmout;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.rmAmount = this.monthRmAmount;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.tollAmount = this.monthlyTollLimitAmount;

      const calculatedTotal =
        parseFloat(this.fuelAmount.replace(/[^0-9|\\.]/g, '')) +
        parseFloat(this.rmAmount.replace(/[^0-9|\\.]/g, '')) +
        parseFloat(this.tollAmount.replace(/[^0-9|\\.]/g, ''));
      const currencyType = new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',
      });
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-unused-expressions, no-sequences
      (this.monthlyTotalLimitAmount = calculatedTotal), currencyType;

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.monthlyTotalLimitAmount = currencyType.format(this.monthlyTotalLimitAmount);

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties

      return calculatedTotal;
    },
  },
  watch: {
    monthFuelAmout(value) {
      this.vehicle.productSetup.monthFuelAmout = value;
    },
    monthRmAmount(value) {
      this.vehicle.productSetup.monthRmAmount = value;
    },
    monthlyTollLimitAmount(value) {
      this.vehicle.productSetup.monthlyTollLimitAmount = value;
    },
    monthlyTotalLimitAmount(value) {
      this.vehicle.productSetup.monthlyTotalLimitAmount = value;
    },
    productCode(value) {
      this.vehicle.productSetup.productCode = value;
    },
  },

  created() {
    this.getOrtCountry();
    this.getOrtVehicleClass();
    this.getOrtAxleClass();
    this.vehicleIndicators();
    this.ortIndicators();
    this.checkTransationLimit();
    this.checkValueAdd();
    this.checkMerchantLimit();
    this.checkCard();
  },
  methods: {
    ...mapActions('vehicles', ['getGeneralCodes', 'getRegionByCountry', 'getCountries', 'getOrtIndicators']),
    ...mapActions('clients', ['getValueAddCode', 'getVehicleIndicators']),

    addMerchantLimit() {
      this.merchantLimit.push({});
    },

    deleteMerchantLimit(index) {
      this.merchantLimit.splice(index, 1);
    },

    addValueAddProduct() {
      this.valueAddProducts.push({});
    },

    deleteValueAddProduct(index) {
      this.valueAddProducts.splice(index, 1);
    },

    addItem() {
      let newItem = {};

      newItem = new ProductSetupItemValueAdd();

      newItem.index = this.vehicle.productSetup.sections.products.length;
      this.vehicle.productSetup.sections.products.push(newItem);
    },
    addItems() {
      let newItem = {};

      newItem = new MerchantSetupItemValueAdd();

      newItem.index = this.vehicle.productSetup.sections.merchants.length;
      this.vehicle.productSetup.sections.merchants.push(newItem);
    },

    updateJobCardItem(item) {
      if (item.selectedItem) {
        this.vehicle.productSetup.sections.products[item.index].productCode = item.selectedItem.productCode;
        this.vehicle.productSetup.sections.products[item.index].productDescription = item.selectedItem.productDesc;
        this.vehicle.productSetup.sections.products[item.index].instalmentFee = item.selectedItem.instalmentFee;
        this.vehicle.productSetup.sections.products[item.index].serviceFee = item.selectedItem.serviceFee;
        this.vehicle.productSetup.sections.products[item.index].driverAllowance = item.selectedItem.driverAllwnc;
        this.vehicle.productSetup.sections.products[item.index].driverBilling = item.selectedItem.driverBill;
        this.vehicle.productSetup.sections.products[item.index].productDescription = item.selectedItem.productDesc;
        this.vehicle.productSetup.sections.products[item.index].systemInd = item.selectedItem.systemInd;
        this.vehicle.productSetup.sections.products[item.index].effectiveDate = this.currentDate;
      }
    },

    updateJobCardItems(item) {
      if (item.selectedItem) {
        this.vehicle.productSetup.sections.merchants[item.index].merchantTypeCode = item.selectedItem.merchantCode;
        this.vehicle.productSetup.sections.merchants[item.index].merchantTypeDescription = item.selectedItem.merchantDesc;
        this.vehicle.productSetup.sections.merchants[item.index].dailyTransCountLimitDFLT = item.selectedItem.dlyTransLimit;
        this.vehicle.productSetup.sections.merchants[item.index].minHoursDFLT = item.selectedItem.minHrsBtwn;
        this.vehicle.productSetup.sections.merchants[item.index].transactionValueLimitDefault = item.selectedItem.transValLimit;
      }
    },
    deleteItem(item) {
      const index = this.vehicle.productSetup.sections.products.indexOf(item);
      this.vehicle.productSetup.sections.products.splice(index, 1);
    },
    deleteItems(item) {
      const index = this.vehicle.productSetup.sections.merchants.indexOf(item);
      this.vehicle.productSetup.sections.merchants.splice(index, 1);
    },
    onSystem4DealSelect() {
      if (this.vehicle.productSetup.systemDeal === 'N') {
        this.hasSystem4Deal = false;
      } else {
        this.hasSystem4Deal = true;
      }
    },

    onTransactionLimitSelect() {
      if (this.vehicle.productSetup.transactionLimit === 'N') {
        this.showTransactionLimitDetails = false;
        this.monthFuelAmout = '';
        this.monthRmAmount = '';
        this.monthlyTollLimitAmount = '';
        this.vehicle.productSetup.monthLitreLimit = '';
        this.monthlyTotalLimitAmount = '';
      } else {
        this.showTransactionLimitDetails = true;
      }
    },
    onOrtAllowedSelect() {
      if (this.vehicle.productSetup.ortAllowed === 'N') {
        this.showOrtAllowedDetails = false;
      } else {
        this.showOrtAllowedDetails = true;
      }
    },
    onRegisterAllowedSelect() {
      if (this.vehicle.productSetup.registerForOrt === 'N' && this.ortRegister === 'Y') {
        this.msg.registerForOrt = 'ORT INFORMATION DOES NOT MATCH THE DEFAULT';
      } else if (this.vehicle.productSetup.registerForOrt === 'Y' && this.ortRegister === 'N') {
        this.msg.registerForOrt = 'CLIENT MUST BE REGISTERD FOR ORT';
      } else {
        this.msg.registerForOrt = '';
      }
    },
    onClientSubsidySelect() {
      if (this.vehicle.productSetup.clientSubsidy === 'Y' && this.subsidyClient === 'N') {
        this.msg.clientSubsidy = 'CLIENT SUBSIDY MAY NOT BE SELECTED -NO SUBSIDY INFORMATION LOADED';
      } else {
        this.msg.clientSubsidy = '';
      }
    },
    onOilSubsidySelect() {
      if (this.vehicle.productSetup.oilSubsidy === 'Y' && this.oilCompanySub === 'N') {
        this.msg.oilSubsidy = 'INDICATOR MAY ONLY BE Y IF VEHICLE IS LINKED TO AN OIL COMPANY CARD';
        // this.vehicle.productSetup.registerForOrt = 'N';
      } else {
        this.msg.oilSubsidy = '';
      }
    },
    onTruckFuelSelect() {
      if (this.vehicle.productSetup.truckFuelNet === 'Y' && this.tfn === 'N') {
        this.msg.truckFuelNet = 'TFN IND IS NOT SET ON CLIENT LEVEL';
      } else if (this.vehicle.productSetup.truckFuelNet === 'N' && this.vehicle.productSetup.primaryCardType === 'TN') {
        this.msg.truckFuelNet = 'TRUCKFUELNET IS SELECTED BUT TFN INDICATOR IS SET TO N';
      } else {
        this.msg.truckFuelNet = '';
      }
    },
    onBpFleetSelect() {
      if (this.vehicle.productSetup.bdfleetMoveRfid === 'Y' && this.bpfleet === 'N') {
        this.msg.bdfleetMoveRfid = 'BP FLEETMOVE IND IS NOT SET ON CLIENT LEVEL';
      } else {
        this.msg.bdfleetMoveRfid = '';
      }
    },
    onBdNamibiaSelect() {
      if (this.vehicle.productSetup.bdfleetMoveRfidsNamibia === 'Y' && this.bpNamibia === 'N') {
        this.msg.bdfleetMoveRfidsNamibia = 'NAMIBIA PAYMENT24 IND IS NOT SET ON CLIENT LEVEL';
      } else {
        this.msg.bdfleetMoveRfidsNamibia = '';
      }
    },
    onFraudSelect() {
      if (this.vehicle.productSetup.fraudOdo === 'Y' && this.fraudInd === 'N') {
        this.msg.fraudOdo = 'FRAUD ODO INDICATOR IS NOT SET ON CLIENT LEVEL';
      } else {
        this.msg.fraudOdo = '';
      }
    },
    onOilCompanySelect() {
      if (this.vehicle.productSetup.oilCompanyCard === 'Y' && this.vehicle.productSetup.cardSystemIndicator !== 'Y') {
        this.msg.oilCompanyCard = 'OIL CARD INDICATOR AND CARD TYPE DO NOT MATCH';
      } else {
        this.msg.oilCompanyCard = '';
      }
    },
    onDualSelect() {
      if (this.vehicle.productSetup.dualClass === 'Y' && this.vehicle.productSetup.ortVehicleClass === 'A1-MOTORCYCLES') {
        this.msg.dualClass = 'DUAL CLASS INDICATOR MAY NOT BE SPECIFIED FOR THIS VEHICLE';
      } else if (this.vehicle.productSetup.dualClass === 'Y' && this.vehicle.productSetup.ortVehicleClass === 'A2-LIGHT VEH') {
        this.msg.dualClass = 'DUAL CLASS INDICATOR MAY NOT BE SPECIFIED FOR THIS VEHICLE';
      } else {
        this.msg.dualClass = '';
      }
    },
    async ortIndicators() {
      const payload = {
        modelExtention: this.vehicle.vehicleInformation.modelExtension,
        registrationNumber: this.vehicle.vehicleInformation.vehicleRegistrationNumber,
        clientId: this.clientId,
      };
      const ortIndicators = await this.getOrtIndicators(payload);

      if (ortIndicators.vehicleOrt !== null) {
        this.vehicle.productSetup.ortVehicleClass = ortIndicators.vehicleOrt.ortVehicleClass;
        this.vehicle.productSetup.ortCountry = ortIndicators.vehicleOrt.ortCountry;
        this.vehicle.productSetup.dualClass = ortIndicators.vehicleOrt.dualClass;
        this.vehicle.productSetup.ortAxleClass = ortIndicators.vehicleOrt.ortAxleClass;
        this.vehicle.productSetup.ortRegion = ortIndicators.vehicleOrt.ortRegion;
        this.vehicle.productSetup.ortAllowed = ortIndicators.vehicleOrt.ortAllowed;
      }
    },
    onCardRequiredSelect() {
      if (this.vehicle.productSetup.cardRequired === 'N') {
        this.showCardRequiredDetails = false;
      } else {
        this.showCardRequiredDetails = true;
      }
    },
    checkTransationLimit() {
      if (this.vehicle.productSetup.transactionLimit != null) {
        if (this.vehicle.productSetup.transactionLimit === 'Y') {
          if (this.vehicle.productSetup.monthRmAmount != null) {
            this.monthRmAmount = this.vehicle.productSetup.monthRmAmount;
          }

          if (this.vehicle.productSetup.monthFuelAmout != null) {
            this.monthFuelAmout = this.vehicle.productSetup.monthFuelAmout;
          }
          if (this.vehicle.productSetup.monthlyTollLimitAmount != null) {
            this.monthlyTollLimitAmount = this.vehicle.productSetup.monthlyTollLimitAmount;
          }
          if (this.vehicle.productSetup.monthlyTotalLimitAmount != null) {
            this.monthlyTotalLimitAmount = this.vehicle.productSetup.monthlyTotalLimitAmount;
          }
          this.showTransactionLimitDetails = true;
        }
      }
    },
    checkValueAdd() {
      if (this.vehicle.productSetup.valueAdd != null) {
        if (this.vehicle.productSetup.valueAdd !== 1) {
          this.hasValueAddedProducts = true;
        }
      }
    },
    checkMerchantLimit() {
      if (this.vehicle.productSetup.merchantLimit != null) {
        if (this.vehicle.productSetup.merchantLimit === 0) {
          this.hasMerchantLimit = true;
        }
      }
    },
    checkCard() {
      if (this.vehicle.productSetup.cardRequired != null) {
        if (this.vehicle.productSetup.cardRequired === 'Y') {
          this.showCardRequiredDetails = true;
        }
      }
    },

    onMerchantLimitSelect() {
      if (this.vehicle.productSetup.merchantLimit === 1) {
        this.hasMerchantLimit = false;
        this.vehicle.productSetup.sections.merchants = [];
      } else {
        this.hasMerchantLimit = true;
      }
    },

    onValueAddedSelect() {
      if (this.vehicle.productSetup.valueAdd === 1) {
        this.hasValueAddedProducts = false;
        this.vehicle.productSetup.sections.products = [];
      } else {
        this.hasValueAddedProducts = true;
      }
    },

    async getOrtVehicleClass() {
      const code = '220';
      const tempOrtVehicleClass = await this.getGeneralCodes(code);

      if (tempOrtVehicleClass !== null) {
        for (let i = 0; i < tempOrtVehicleClass.generalCodes.length; i += 1) {
          this.ortVehClass.push(tempOrtVehicleClass.generalCodes[i].description);
          this.ortVehClass.sort();
        }
      } else {
        this.ortVehClass = [];
      }
    },
    async getOrtAxleClass() {
      const code = '192';
      const tempOrtAxleClass = await this.getGeneralCodes(code);

      if (tempOrtAxleClass !== null) {
        for (let i = 0; i < tempOrtAxleClass.generalCodes.length; i += 1) {
          this.ortAxleClass.push(tempOrtAxleClass.generalCodes[i].description);
          this.ortAxleClass.sort();
        }
      } else {
        this.ortAxleClass = [];
      }
    },
    async vehicleIndicators() {
      const vehicleIndicators = await this.getVehicleIndicators(this.clientId);

      if (vehicleIndicators.clientVehicleIndicators !== null) {
        this.ortRegister = vehicleIndicators.clientVehicleIndicators.ortClient;
        this.subsidyClient = vehicleIndicators.clientVehicleIndicators.subsidy;
        this.tfn = vehicleIndicators.clientVehicleIndicators.tfnIndicator;
        this.bpfleet = vehicleIndicators.clientVehicleIndicators.bpIndicator;
        this.bpNamibia = vehicleIndicators.clientVehicleIndicators.payment24Indicator;
        this.fraudInd = vehicleIndicators.clientVehicleIndicators.fraudOdo;
        this.oilCompanySubsidy = vehicleIndicators.clientVehicleIndicators.oilCompanySubsidy;
        this.oilCompany = vehicleIndicators.clientVehicleIndicators.oilCompanyAlliance;
        this.oilCompanySub = vehicleIndicators.clientVehicleIndicators.subsidy;
        if (vehicleIndicators.clientVehicleIndicators.driverAllowance === 'N' && vehicleIndicators.clientVehicleIndicators.driverBill === 'N') {
          this.vehicle.productSetup.driverToPayInHouse = 'N';
          this.driverToPay = true;
        }
      }
    },
    async getOrtCountry() {
      const tempOrtCountry = await this.getCountries();

      if (tempOrtCountry !== null) {
        for (let i = 0; i < tempOrtCountry.ortCountries.length; i += 1) {
          this.ortCountries.push(tempOrtCountry.ortCountries[i].country);
          this.ortCountries.sort();
        }
      } else {
        this.ortCountries = [];
      }
    },

    async getOrtRegions(country) {
      const tempOrtRegions = await this.getRegionByCountry(country);

      if (tempOrtRegions !== null) {
        for (let i = 0; i < tempOrtRegions.getRegions.length; i += 1) {
          this.ortRegion.push(tempOrtRegions.getRegions[i].description);
          this.ortRegion.sort();
        }
      } else {
        this.ortRegion = [];
      }
    },
    onCardType(selectedCardType) {
      this.vehicle.productSetup.primaryCardType = selectedCardType.code;
      this.vehicle.productSetup.cardSystemIndicator = selectedCardType.systemIndicator;
      this.vehicle.productSetup.description = selectedCardType.description;
    },
    onSecondaryCardType(selectedCardType) {
      this.vehicle.productSetup.secondaryCardType = selectedCardType.code;
    },
    onAdminFee(selectedAdminFee) {
      this.vehicle.productSetup.adminFeeGroup = selectedAdminFee.adminfeeAmount;
    },
    onAuthorityGroup(selectedAuthorityGroup) {
      this.vehicle.productSetup.authorityGroup = selectedAuthorityGroup.authorityLimit;
    },

    onSelectedItem(selectedItem) {
      this.vehicle = { ...selectedItem.selectedItem };
    },
  },
};
</script>
